/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { observable, action } from 'mobx';
import { saveAs } from 'file-saver';

// Stores
import ValidationStore from '@Stores/ValidationStore';

// Providers
import { FileManagerProvider, VaultProvider } from '@Providers';
import CommonStore from '@Stores/CommonStore';

class ImportLogStore extends ValidationStore {
  importLogs = observable.array([]);

  @observable offset = 0;

  @observable limit = 25;

  @observable lastPage = false;

  @observable sort = 'audit.updatedAt,desc';

  @observable loading = false;

  @observable currentLength = this.importLogs.length;

  @action('ImportLogStore => getImportLogs')
  getImportLogs = type => {
    if (!this.loading) {
      this.updateLoading(true);
      const query = {
        version: 1,
        sort: this.sort,
        offset: type === 'update' ? 0 : this.offset,
        limit: type === 'update' ? this.importLogs.length : this.limit
      };
      return VaultProvider.getImportLogs(query)
        .then(response => {
          this._setImportLogs(response, type);
        })
        .finally(() => this.updateLoading(false))
        .catch(this._handleRequestError);
    }
    return null;
  };

  @action('ImportLogStore => updateLoading')
  updateLoading = loading => {
    if (loading) {
      CommonStore.setPending();
    } else {
      CommonStore.clearPending();
    }
    this.loading = loading;
  };

  @action('ImportLogStore => downloadFile')
  downloadLogFile = (guid, filename) => {
    return FileManagerProvider.downloadFile(guid)
      .then(response => this.download(response, filename))
      .catch(this._handleRequestError);
  };

  download(response, filename) {
    const fileType = response['content-type'];
    const blob = new Blob([response], { type: fileType });
    saveAs(blob, filename);
  }

  @action('ImportLogStore => setImportLogs')
  _setImportLogs = (response, type) => {
    this.lastPage = !response.pagination.hasNext;
    const data = [...response.data];
    const updatedData = data.map(log => {
      const started = log.statusHistory.find(
        record => record.status === 'STARTED'
      );
      const finished = log.statusHistory.find(
        record => record.status === 'FINISHED'
      );
      return {
        inputFile: log.inputFile,
        outputFile: log.outputFile,
        processingStatistics: log.processingStatistics,
        startedAt: started ? started.statusAt : null,
        finishedAt: finished ? finished.statusAt : null,
        currentStatus: log.currentStatus
      };
    });

    if (this.offset === 0) {
      this.importLogs.replace(updatedData);
      return;
    }

    if (type === 'update') {
      this.importLogs.replace(updatedData);
      return;
    }

    const updatedImportLogs = [...this.importLogs, ...updatedData];

    this.importLogs.replace(updatedImportLogs);
  };

  @action('ImportLogStore => changeOffset') changeOffset = () => {
    this.offset = this.offset + this.limit;
  };

  @action('ImportLogStore => resetList')
  resetList = () => {
    this.resetOffset();
    this.importLogs.replace([]);
  };

  @action('ImportLogStore => resetOffset') resetOffset = () => {
    this.offset = 0;
  };
}

export default new ImportLogStore();
