const styles = () => ({
  skipTopPadding: {
    paddingTop: 0
  },
  title: {
    color: '#2F0A6B',
    fontSize: '16px',
    fontWeight: '500',
    paddingLeft: '40px',
    position: 'relative',
    '& svg': {
      position: 'absolute',
      left: 0
    }
  }
});

export default styles;
