/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

import { getParsedCookies } from '@Utils/formatting';

export default requests => ({
  getEmployeeProfile: queryParams =>
    requests.get(`/v1/data/EMPLOYEE_PROFILE?${queryParams}`, null, {
      'X-Tenant': getParsedCookies().tenant
    }),
  getProfiles: queryParams =>
    requests.get('/v1/data/EMPLOYEE_PROFILE', queryParams, {
      'X-Tenant': getParsedCookies().tenant
    }),
  getImportLogs: queryParams =>
    requests.get('/v1/data/PROCESSING_HISTORY', queryParams, {
      'X-Tenant': getParsedCookies().tenant
    }),
  getLatestUploadStatus: queryParams =>
    requests.get('/v1/data/PROCESSING_HISTORY', queryParams, {
      'X-Tenant': getParsedCookies().tenant
    }),
  getCompanyProfile: queryParams =>
    requests.get('/v1/data/COMPANY_PROFILE', queryParams, {
      'X-Tenant': getParsedCookies().tenant
    }),
  saveCompanyProfile: (payload, query) =>
    requests.post('/v1/data/COMPANY_PROFILE', payload, query, false, {
      'X-Tenant': getParsedCookies().tenantGroup
    }),
  updateCompanyProfile: (guid, payload, query) =>
    requests.put(`/v1/data/COMPANY_PROFILE/${guid}?${query}`, payload, {
      'X-Tenant': getParsedCookies().tenantGroup
    }),
  findAggregatedCovidResultData: queryParams =>
    requests.get('/v1/reports/test-result/chart', queryParams, {
      'X-Tenant': getParsedCookies().tenantGroup
    }),
  getCovidTestResultOverallReport: queryParams =>
    requests.get('/v1/reports/test-result/overall', queryParams, {
      'X-Tenant': getParsedCookies().tenantGroup
    }),
  getCovidTestResultGroupReport: (groupKey, queryParams) =>
    requests.get(`/v1/reports/test-result/groups/${groupKey}`, queryParams, {
      'X-Tenant': getParsedCookies().tenantGroup
    }),
  getTravelReport: queryParams =>
    requests.get('/v1/reports/travel', queryParams, {
      'X-Tenant': getParsedCookies().tenantGroup
    })
});
