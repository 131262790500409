/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

// Components
import { MainContentWrapper, Notification } from '@CommonScene';
import TableWrapper from '@MyTeamScene/scenes/MyTeamList/components/TableWrapper';
import ImportLogTableWrapper from '@MyTeamScene/scenes/MyTeamList/components/ImportLogTableWrapper';

// Utils
import { compose } from '@Utils';
import { getMyTeamPath } from '@Utils/constans/paths';

// Constants
import { CREATE_INVITATIONS } from '@Utils/constans/permissions';
import { Route, Switch, withRouter } from 'react-router-dom';
import { BULK_UPLOAD_STATUS } from '@Utils/constans';

export class MembersList extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    AuthStore: PropTypes.object.isRequired,
    MyTeamStore: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  getHeaderOptions() {
    return {
      title: 'My Team'
    };
  }

  getButtonOptions = () => {
    const {
      history,
      AuthStore: { permissions }
    } = this.props;

    return {
      isShown:
        permissions.includes(CREATE_INVITATIONS) &&
        this.props.location.pathname === getMyTeamPath.myTeam(),
      text: 'add',
      onClickHandler: () => {
        history.push(getMyTeamPath.newTeamMember());
      }
    };
  };

  getTabsOptions() {
    return {
      tabs: [
        {
          title: 'Participants',
          isActive: this.props.location.pathname === getMyTeamPath.myTeam(),
          onClick: () => {
            this.props.history.push({
              pathname: getMyTeamPath.myTeam()
            });
          }
        },
        {
          title: 'Import Log',
          isActive: this.props.location.pathname.includes(
            getMyTeamPath.importLog()
          ),
          onClick: () => {
            this.props.history.push({
              pathname: getMyTeamPath.importLog()
            });
          }
        }
      ]
    };
  }

  render() {
    const {
      latestUploadHistory,
      closeUploadNotification,
      lastClosedUploadNotification,
      showResendNotification,
      resendNotificationMsg,
      newUpload
    } = this.props.MyTeamStore;

    return (
      <MainContentWrapper
        headerOptions={this.getHeaderOptions()}
        buttonOptions={this.getButtonOptions()}
        tabsOptions={this.getTabsOptions()}
      >
        {latestUploadHistory && (
          <Notification
            hasError={
              latestUploadHistory.status === BULK_UPLOAD_STATUS.FINISHED &&
              latestUploadHistory.hasFailure
            }
            label="New team members are being uploaded"
            errorLabel="Some team members were not uploaded due to failures"
            isHidden={
              (latestUploadHistory.status === BULK_UPLOAD_STATUS.FINISHED &&
                !latestUploadHistory.hasFailure) ||
              lastClosedUploadNotification === latestUploadHistory.guid
            }
            onClose={closeUploadNotification}
          />
        )}

        <Notification
          label="New team members are being uploaded"
          errorLabel="Some team members were not uploaded due to failures"
          isHidden={!newUpload}
          onClose={closeUploadNotification}
        />

        <Notification
          hasSuccess
          successLabel={resendNotificationMsg}
          isHidden={!showResendNotification}
          onClose={() =>
            this.props.MyTeamStore.updateShowResendNotification(false)
          }
        />
        <Switch>
          <Route path={getMyTeamPath.myTeam()} exact component={TableWrapper} />
          <Route
            path={getMyTeamPath.importLog()}
            exact
            component={ImportLogTableWrapper}
          />
        </Switch>
      </MainContentWrapper>
    );
  }
}

export default compose(
  inject('AuthStore', 'MyTeamStore'),
  withRouter,
  observer
)(MembersList);
