/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { readXLSX } from '@Utils/xlsx';

// Material UI
import { withStyles } from '@material-ui/core';

// Components
import DownloadTemplate from '@MyTeamScene/scenes/NewTeamMember/components/BulkUploadForm/DownloadTemplate';
import ChooseSendInvitation from '@MyTeamScene/scenes/NewTeamMember/components/BulkUploadForm/ChooseSendInvitation';
import BulkUpload from '@MyTeamScene/scenes/NewTeamMember/components/BulkUploadForm/BulkUpload';
import { HorizontalStepper } from '@CommonScene';

// Utils
import { compose } from '@Utils';
import { SEND_INVITATION_OPTIONS } from '@Utils/constans';

// Styles
import styles from './styles';

const BulkUploadFormContainer = ({
  CommonStore,
  MyTeamStore: {
    bulkUpload,
    getBulkTemplateFile,
    bulkTemplateFileName,
    bulkTemplateDownloadURL,
    bulkTemplateHeaders,
    sendEmployeeUploadStartedEvent,
    setNewUpload
  },
  history
}) => {
  const [isComplete, setIsComplete] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [selectedSendInvitation, setSendInvitation] = React.useState(null);
  const [isFileProcessing, setFileProcessing] = React.useState(false);

  React.useEffect(() => {
    getBulkTemplateFile();
  }, []);

  const deferredSetFileProcessing = React.useCallback((value, callback) => {
    setTimeout(() => {
      setFileProcessing(value);

      if (callback) {
        callback();
      }
    }, 300);
  }, []);

  const onFile = React.useCallback(
    file => {
      setSelectedFile(file);
      setFileProcessing(true);

      readXLSX(file, { sheetRows: 3 })
        .catch(() => {
          deferredSetFileProcessing(false, () =>
            setError('An error has occured while reading the file')
          );
        })
        .then(([sheet]) => {
          const headers = sheet[0] || [];

          if (bulkTemplateHeaders.join() !== headers.join()) {
            return deferredSetFileProcessing(false, () =>
              setError(
                'Your file structure does not match with the original template'
              )
            );
          }

          const requiredLine = sheet[2] || [];

          if (!requiredLine.join('')) {
            return deferredSetFileProcessing(false, () =>
              setError('Your file should contain at least 1 item')
            );
          }

          const phoneColumn = requiredLine[5];

          if (typeof phoneColumn !== 'string') {
            return deferredSetFileProcessing(false, () =>
              setError('The phone number should be in text format')
            );
          }

          deferredSetFileProcessing(false, () => setIsComplete(true));

          return false;
        });
    },
    [bulkTemplateHeaders]
  );

  const onResetUploadBox = React.useCallback(() => {
    setError(null);
    setIsComplete(false);
    setSelectedFile(null);
    setFileProcessing(false);
  }, []);

  const onChangeSendInvitation = React.useCallback(e => {
    setSendInvitation(e.target.value);
  }, []);

  const getStepContent = React.useCallback(
    index => {
      switch (index) {
        case 0:
          return (
            <DownloadTemplate
              fileName={bulkTemplateFileName}
              url={bulkTemplateDownloadURL}
            />
          );
        case 1:
          return (
            <ChooseSendInvitation onChange={onChangeSendInvitation} disabled />
          );
        case 2:
          return (
            <BulkUpload
              file={selectedFile}
              onFile={onFile}
              onReset={onResetUploadBox}
              error={error}
              isLoading={isFileProcessing}
            />
          );

        default:
          return null;
      }
    },
    [
      selectedFile,
      error,
      bulkTemplateDownloadURL,
      bulkTemplateFileName,
      onFile,
      isFileProcessing
    ]
  );

  const handleSubmit = React.useCallback(() => {
    CommonStore.setPending();
    bulkUpload(selectedFile)
      .then(response => {
        sendEmployeeUploadStartedEvent(
          response.guid,
          selectedSendInvitation === SEND_INVITATION_OPTIONS.SEND
        ).then(() => {
          setNewUpload(true);
          history.push('/my-team');
        });
      })
      .finally(() => {
        CommonStore.clearPending();
      });
  }, [selectedSendInvitation, selectedFile]);

  return (
    <HorizontalStepper
      steps={[
        'Download template',
        'Choose invitation option',
        'Upload & validate file'
      ]}
      getStepContent={getStepContent}
      handleSubmit={handleSubmit}
      isComplete={isComplete}
      hasError={Boolean(error)}
      isDisabled={bulkTemplateHeaders.length === 0 || CommonStore.pending}
    />
  );
};

BulkUploadFormContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  MyTeamStore: PropTypes.object.isRequired,
  CommonStore: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  inject('MyTeamStore', 'CommonStore'),
  withStyles(styles),
  withRouter,
  observer
)(BulkUploadFormContainer);
