/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright Â© 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Material UI
import { Grid } from '@material-ui/core';

// Components
import { PhoneNumberFieldNew, Checkbox, TextField } from '@CommonScene';
import FieldWrapper from '@CommonScene/FieldWrapper';

// Components
import SignupWrapper from '@AuthScene/scenes/common/SignupWrapper';
import ErrorSection from '@AuthScene/scenes/common/ErrorSection';

// Utils
import { compose, generateGUID } from '@Utils';
import { useStore } from '@Utils/hooks';

// styles
import useStyles from './styles';

const CreateAnAccount = ({ history }) => {
  const [isTermsChecked, checkboxOnChange] = useState(false);
  const [serverError, setServerError] = useState(false);

  const {
    SignUpStore: {
      setNextStep,
      resetStore,
      forms,
      onFieldChange,
      verifyInvitation,
      errors,
      setErrorMessage
    }
  } = useStore();

  const onLeftButtonClickHandler = useCallback(() => {
    history.push('/login');
    resetStore();
  });

  const onRightButtonClickHandler = useCallback(() => {
    setServerError(false);
    setErrorMessage(null);
    const fields = forms.invitationVerification.fields;
    verifyInvitation({
      care_wallet_id: generateGUID(),
      code: fields.invitationCode.value,
      phone: `+${fields.phone.value}`
    })
      .then(response => {
        window.localStorage.setItem('tempT', response.access_token);
        window.localStorage.setItem('cwId', response.careWalletId);
        setNextStep();
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          error.response.body &&
          error.response.body.errors &&
          error.response.body.errors[0] &&
          error.response.body.errors[0].message
        ) {
          setErrorMessage(error.response.body.errors[0].message);
        } else {
          setServerError(true);
        }
      });
  });

  const handlePhoneNumberChange = (value, name) => {
    onFieldChange(name, value, 'invitationVerification');
  };

  const handleInvitationCodeChange = e => {
    // clearErrorMessage();
    onFieldChange(e.target.name, e.target.value, 'invitationVerification');
  };

  const handleTermsAcceptanceChange = e => {
    checkboxOnChange(e.target.checked);
  };

  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <SignupWrapper
      onLeftButtonClick={onLeftButtonClickHandler}
      onRightButtonClick={onRightButtonClickHandler}
      leftButtonText={'Login'}
      title="Create an account"
      disabledRightButton={
        !forms.invitationVerification.meta.isValid || !isTermsChecked
      }
      withStepper={false}
    >
      <Grid container>
        {serverError ? (
          <ErrorSection errors={t('auth.login.unhandledError')} />
        ) : null}
        <PhoneNumberFieldNew
          label="Login"
          name="phone"
          onChange={value => handlePhoneNumberChange(value, 'phone')}
          value={forms.invitationVerification.fields.phone.value}
          error={forms.invitationVerification.fields.phone.error}
        />
        <FieldWrapper label="Invitation code">
          <TextField
            name="invitationCode"
            onChange={handleInvitationCodeChange}
            value={forms.invitationVerification.fields.invitationCode.value}
            error={
              forms.invitationVerification.fields.invitationCode.error || errors
            }
            fullWidthorm
          />
        </FieldWrapper>
        <FieldWrapper>
          <p className={classes.terms}>
            <Checkbox
              name="termsAcceptance"
              onChange={handleTermsAcceptanceChange}
              checked={isTermsChecked}
            />
            I accept{' '}
            <a
              href="https://solve.care/docs/team-care-network-terms-and-conditions-of-use/team-care-network-terms-and-conditions-of-use-1588758837.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>{' '}
            and{' '}
            <a
              href="https://solve.care/docs/solve-care-website-privacy-policy/solve-care-website-privacy-policy-1588693964.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy policy
            </a>
          </p>
        </FieldWrapper>
      </Grid>
    </SignupWrapper>
  );
};

CreateAnAccount.propTypes = {
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  observer
)(CreateAnAccount);
