// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
// Components
import { DataTable } from '@CommonScene';
// Configs
import { tableConfig } from '@Assets/config/tables/reportedTrips';
// Utils
import { compose } from '@Utils';
import { Person } from '@Utils/constans/icons';
import ContainerHeader from '@CommonScene/MainContentWrapper/components/ContainerHeader';
import { Typography, withStyles } from '@material-ui/core';

import styles from './styles';

export class TableWrapper extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    ReportedTripsStore: PropTypes.object.isRequired
  };

  componentDidMount() {
    const {
      ReportedTripsStore: { getTrips }
    } = this.props;

    getTrips();
  }

  componentWillUnmount() {
    const {
      ReportedTripsStore: { resetTripList, resetOffset }
    } = this.props;

    resetOffset();
    resetTripList();
  }

  fetchTableData = () => {
    const {
      ReportedTripsStore: { changeOffset, getTrips }
    } = this.props;

    changeOffset();
    getTrips();
  };

  setDefaultQuery = () => {
    const {
      ReportedTripsStore: { getTrips, resetOffset }
    } = this.props;

    resetOffset();
    getTrips();
  };

  updateActiveTab = tab => {
    const {
      updateActiveTab,
      getTrips,
      resetTripList,
      updateLastPage,
      resetOffset
    } = this.props.ReportedTripsStore;
    updateActiveTab(tab);
    resetOffset();
    resetTripList();
    updateLastPage(false);
    getTrips();
  };

  tableTitle = () => {
    const {
      classes,
      ReportedTripsStore: { activeTab }
    } = this.props;
    const title = (
      <Typography className={classes.title}>
        <Person /> Reported Trips By People
      </Typography>
    );
    const options = {
      title
    };
    const tabs = [
      {
        title: 'Past',
        onClick: () => this.updateActiveTab('past'),
        isActive: activeTab === 'past'
      },
      {
        title: 'Future',
        onClick: () => this.updateActiveTab('upcoming'),
        isActive: activeTab === 'upcoming'
      }
    ];
    return (
      <ContainerHeader
        classes={{ wrapper: classes.skipTopPadding }}
        options={options}
        tabsOptions={{ tabs }}
      />
    );
  };

  render() {
    const { lastPage, trips, query, setQuery } = this.props.ReportedTripsStore;
    return (
      <DataTable
        tableConfig={tableConfig}
        tableData={trips}
        fetchTableData={this.fetchTableData}
        hasMoreData={!lastPage}
        customTitle={this.tableTitle()}
        searchConfig={{
          setQuery,
          queryValue: query,
          setDefaultQuery: this.setDefaultQuery
        }}
      />
    );
  }
}

export default compose(
  withStyles(styles),
  inject('ReportedTripsStore'),
  observer
)(TableWrapper);
