/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import i18n from 'i18next';

// Utils
import { formatDate, formatPhoneNumber } from '@Utils/formatting';

import { translation } from '@Utils/translation';

// Components
import { SendIcon, ResendIcon, RevokeIcon } from '@Utils/constans/icons';

// Stores
import MyTeamStore from '@Stores/MyTeamStore';
import { getMyTeamPath } from '@Utils/constans/paths';

export const renderRolesNames = key => translation(key, 'rolesNames');

export const handleResendCode = ({ rowId }) => {
  MyTeamStore.resendInvitation(rowId);
  MyTeamStore.resetMembersList();
  MyTeamStore.getTeamMembers();
};

export const handleSendCode = ({ rowId }) => {
  MyTeamStore.sendInvitation(rowId);
  MyTeamStore.resetMembersList();
  MyTeamStore.getTeamMembers();
};

export const handleRevokeCode = ({ rowId }) => {
  MyTeamStore.revokeInvitation(rowId);
  MyTeamStore.resetMembersList();
  MyTeamStore.getTeamMembers();
};
const handleRowClick = (event, id, redirectTo) => {
  event.stopPropagation();
  redirectTo(`${getMyTeamPath.teamMember()}/${id}`);
};

// TODO: commented code not used in October release
export const tableConfig = {
  handleRowClick,
  hasActions: true,
  hasCheckbox: true,
  onHeaderCheckboxChange: MyTeamStore.toggleAllTableRowChecked,
  onCheckboxChange: MyTeamStore.toggleTableRowChecked,
  rowIdKey: 'phone',
  extraIdKey: 'phone',
  headings: [
    'Full name',
    i18n.t('common.phone'),
    'E-mail',
    'Team/Dept.',
    'Office',
    'Participation Status',
    'Last Modified'
  ],
  rowCells: [
    ['firstName', 'lastName'],
    { key: 'phone', cb: formatPhoneNumber },
    'email',
    'teamName',
    'officeAddress',
    'status',
    { key: 'status_updated_at', cb: formatDate }
  ],
  actionsConfig: {
    filterMenuItems: rowData => {
      const sendStatuses = ['NEW'];
      const resendStatuses = ['INVITATION_PENDING', 'INVITATION_EXPIRED'];
      // const revokeStatuses = ['INVITATION_PENDING', 'INVITATION_EXPIRED'];

      // const sendStatuses = ['NEW_USER', 'INVITATION_EXPIRED'];
      // const revokeStatuses = ['INVITATION_PENDING'];
      const includedValues = [...sendStatuses, ...resendStatuses];

      const { status } = rowData;

      // Check permission for send invite

      if (sendStatuses.includes(status)) {
        return [tableConfig.actionsConfig.menuItems[0]];
      }

      if (resendStatuses.includes(status)) {
        return [tableConfig.actionsConfig.menuItems[1]];
      }

      if (includedValues.includes(status)) {
        // TODO: Fix it. Use correct 'this' context (this.actionsConfig.menuItems)
        return tableConfig.actionsConfig.menuItems.filter(
          item => status === item.status
        );
      }
      return [];
    },
    menuItems: [
      {
        name: 'Send invitation',
        icon: <SendIcon />,
        status: 'NEW',
        handleClick: handleSendCode
      },
      {
        name: 'Resend invitation',
        icon: <ResendIcon />,
        status: 'INVITATION_EXPIRED',
        handleClick: handleResendCode
      },
      {
        name: 'Revoke invitation',
        icon: <RevokeIcon />,
        status: 'INVITATION_PENDING',
        handleClick: handleRevokeCode
      }
    ]
  },
  toolbarConfig: {
    items: [
      // {
      //   label: 'Send',
      //   icon: <SendIcon />,
      //   clickHandler: () => {
      //     console.log(
      //       toJS(MyTeamStore.members.filter(member => member.checked))
      //     );
      //   }
      // },
      {
        label: 'Resend',
        icon: <ResendIcon />,
        isDisabled: () => MyTeamStore.checkedPendingExpiredCount <= 0,
        clickHandler: () => {
          if (MyTeamStore.checkedCount > 0) {
            const membersForResend = MyTeamStore.members.filter(member => {
              if (member.checked) {
                if (
                  member.status === 'INVITATION_PENDING' ||
                  member.status === 'INVITATION_EXPIRED'
                ) {
                  return member;
                }
              }
              return false;
            });

            if (membersForResend.length > 0) {
              const requests = membersForResend.map(member => {
                return MyTeamStore.resendInvitation(member.phone);
              });

              Promise.all(requests).then(() => {
                MyTeamStore.resetMembersList();
                MyTeamStore.getTeamMembers();
              });
            }
          }
        }
      }
    ],
    moreItems: [
      // {
      //   id: 'invite-new',
      //   label: 'Invite All New',
      //   clickHandler: () => console.log('invite new')
      // },
      {
        id: 'reinvite-not-joined',
        label: 'Reinvite all not joined yet',
        clickHandler: () => {
          MyTeamStore.resendBatchInvitations([
            'INVITATION_PENDING',
            'INVITATION_EXPIRED'
          ]);
          MyTeamStore.resetMembersList();
          MyTeamStore.getTeamMembers();
          MyTeamStore.updateResendNotificationMsg(
            "The invitation was sent to all participants who haven't joined yet"
          );
          MyTeamStore.updateShowResendNotification(true);
        }
      },
      {
        id: 'reinvite-pending',
        label: 'Reinvite all pending',
        clickHandler: () => {
          MyTeamStore.resendBatchInvitations(['INVITATION_PENDING']);
          MyTeamStore.resetMembersList();
          MyTeamStore.getTeamMembers();
          MyTeamStore.updateResendNotificationMsg(
            'The invitation was sent to all participants with pending status'
          );
          MyTeamStore.updateShowResendNotification(true);
        }
      },
      {
        id: 'reinvite-expired',
        label: 'Reinvite all expired',
        clickHandler: () => {
          MyTeamStore.resendBatchInvitations(['INVITATION_EXPIRED']);
          MyTeamStore.resetMembersList();
          MyTeamStore.getTeamMembers();
          MyTeamStore.updateResendNotificationMsg(
            'The invitation was sent to all participants with expired status'
          );
          MyTeamStore.updateShowResendNotification(true);
        }
      }
      // {
      //   id: 'health-check-reminder',
      //   label: 'Remind all about Daily Health Checkup',
      //   clickHandler: () => {
      //     MyTeamStore.sendHealthReminderEvent();
      //   }
      // }
    ]
  }
};
