/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classnames from 'classnames';

// Material UI
import { Grid, Stepper, Step, StepLabel, withStyles } from '@material-ui/core';
import {
  PrimaryButton,
  SecondaryButton
} from '@CommonScene/materialUIComponents/PrimaryButton';

// Utils
import { compose } from '@Utils';
import { IconDone, Close } from '@Utils/constans/icons';

// Styles
import styles from './styles';

const StepIcon = withStyles(styles)(
  ({ classes, icon, error, completed, active }) => {
    return (
      <div
        className={classnames(classes.stepIcon, {
          active,
          completed,
          error
        })}
      >
        {completed && <IconDone />}
        {error && <Close />}
        {!completed && !error && icon}
      </div>
    );
  }
);

export class HorizontalStepper extends Component {
  static propTypes = {
    steps: PropTypes.array.isRequired,
    getStepContent: PropTypes.func.isRequired,
    hasSubmitButton: PropTypes.bool,
    handleSubmit: PropTypes.func,
    classes: PropTypes.object.isRequired,
    isComplete: PropTypes.bool,
    hasError: PropTypes.bool,
    isDisabled: PropTypes.bool
  };

  state = {
    activeCardIndex: 0,
    activeStep: 0
  };

  handleNext = () => {
    if (this.state.activeStep !== this.props.steps.length) {
      this.setState({ activeStep: this.state.activeStep + 1 });
    }
    window.scrollTo(0, 0);
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  render() {
    const { classes, isComplete, isDisabled, hasError } = this.props;

    return (
      <div>
        <Stepper activeStep={this.state.activeStep}>
          {this.props.steps.map((label, i) => (
            <Step key={label}>
              <StepLabel
                classes={{
                  label: classes.stepLabel
                }}
                StepIconComponent={StepIcon}
                error={this.state.activeStep === i && hasError}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.stepperContent}
        >
          <Grid item> {this.props.getStepContent(this.state.activeStep)} </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.buttonsWrapper}
          spacing={2}
        >
          {this.state.activeStep > 0 ? (
            <Grid item>
              <SecondaryButton
                disabled={isDisabled || hasError}
                onClick={this.handleBack}
              >
                Back
              </SecondaryButton>
            </Grid>
          ) : null}
          {this.state.activeStep !== this.props.steps.length - 1 ? (
            <Grid item>
              <PrimaryButton
                disabled={isDisabled || hasError}
                onClick={this.handleNext}
              >
                Next
              </PrimaryButton>
            </Grid>
          ) : (
            <Grid item>
              <PrimaryButton
                disabled={isDisabled || !isComplete}
                onClick={this.props.handleSubmit}
              >
                Submit
              </PrimaryButton>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  observer
)(HorizontalStepper);
