// Core
import React from 'react';
// Material UI
import Grid from '@material-ui/core/Grid';
// Store
import ReportedTripsStore from '@Stores/ReportedTripsStore';
// Utils
import { formatDate } from '@Utils/formatting';

export const formatTripDestination = (location, rowData) => {
  const { getCountryFlagsById } = ReportedTripsStore;
  if (
    rowData &&
    rowData.trip &&
    rowData.trip.details &&
    rowData.trip.details.city &&
    rowData.trip.details.city.icon
  ) {
    const flag = getCountryFlagsById(rowData.trip.details.city.icon);
    if (flag) {
      return (
        <Grid container direction="row" spacing={1} alignItems="flex-start">
          <Grid item>
            <img
              src={flag}
              width="24px"
              height="16.5px"
              style={{ margin: 'auto', borderRadius: '3px' }}
            />
          </Grid>
          <Grid item>{location}</Grid>
        </Grid>
      );
    }
    return location;
  } else if (location) {
    return location;
  }
  return '';
};

export const tableConfig = {
  rowIdKey: 'profile.email',
  extraIdKey: 'profile.email',
  headings: [
    'Employee name',
    'Employee e-mail',
    'Destination',
    'From',
    'To',
    'Report Date'
  ],
  rowCells: [
    'profile.fullName',
    'profile.email',
    { key: 'trip.destination', formatter: formatTripDestination },
    { key: 'trip.details.from', cb: date => formatDate(date, 'MM/DD/YYYY') },
    { key: 'trip.details.to', cb: date => formatDate(date, 'MM/DD/YYYY') },
    { key: 'trip.statusAt', cb: formatDate }
  ]
};
