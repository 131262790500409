// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

// Utils
import { compose, generateGUID } from '@Utils/index';

// Material UI
import {
  Grid,
  withStyles,
  Typography,
  TextField,
  CircularProgress
} from '@material-ui/core';

// Components
import { BlockWrapper, MainContentWrapper } from '@CommonScene/index';
import { SecondaryButton } from '@CommonScene/materialUIComponents/PrimaryButton';

import styles from './styles';

export class CompanySettings extends Component {
  state = {
    companyName: '',
    originalCompanyName: '',
    hasCompanyNameError: false,
    isNew: true,
    guid: '',
    loading: true
  };

  static propTypes = {
    CompanySettingsStore: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { getCompanyProfile } = this.props.CompanySettingsStore;
    const query = {
      version: 1
    };

    const updateCompanyProfileState = response => {
      if (response.data != null && response.data.length > 0) {
        const companyProfile = response.data[0];
        const { companyName, guid } = companyProfile;
        this.setState({
          companyName,
          guid,
          isNew: false,
          loading: false,
          originalCompanyName: companyName
        });
      }
      this.setState({ loading: false });
    };

    getCompanyProfile(query, updateCompanyProfileState);
  }

  getHeaderOptions() {
    return {
      title: 'Company Settings'
    };
  }

  handleSubmit = () => {
    const { isNew, guid, companyName } = this.state;
    if (!companyName) {
      this.setState({ hasCompanyNameError: true });
    } else {
      const { history, CompanySettingsStore } = this.props;
      const { saveCompanyProfile, updateCompanyProfile } = CompanySettingsStore;
      const redirect = () => history.push('/my-team');
      const payload = {
        companyName,
        guid: generateGUID()
      };
      if (isNew) {
        saveCompanyProfile(payload, { version: 1 }, redirect);
      } else {
        updateCompanyProfile(guid, payload, 'version=1', redirect);
      }
    }
  };

  handleChange = event => {
    const { value } = event.target;
    const hasCompanyNameError = !value;
    this.setState({ companyName: value, hasCompanyNameError });
  };

  render() {
    const { classes } = this.props;
    const {
      loading,
      companyName,
      hasCompanyNameError,
      originalCompanyName
    } = this.state;
    return (
      <MainContentWrapper headerOptions={this.getHeaderOptions()}>
        <BlockWrapper>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <img
                src="/public/img/illustration_company_3x.png"
                className={classes.image}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.contentWrapper}
          >
            <Grid item>
              <Typography className={classes.title}>
                Tell Us Your Company Name
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.contentWrapper}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Grid item xs={2} classes={{ root: classes.label }}>
                  Company Name*
                </Grid>
                <Grid item xs={9} classes={{ root: classes.padding }}>
                  <TextField
                    name="company-name-input"
                    value={companyName}
                    onChange={this.handleChange}
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                    error={hasCompanyNameError}
                    required
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.buttonsWrapper}
          >
            <Grid item>
              <SecondaryButton
                onClick={this.handleSubmit}
                disabled={
                  hasCompanyNameError ||
                  !companyName ||
                  companyName === originalCompanyName
                }
              >
                Submit
              </SecondaryButton>
            </Grid>
          </Grid>
        </BlockWrapper>
      </MainContentWrapper>
    );
  }
}

export default compose(
  withStyles(styles),
  inject('CompanySettingsStore'),
  withRouter,
  observer
)(CompanySettings);
