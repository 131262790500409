/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { action, observable, toJS } from 'mobx';

// Stores
import ValidationStore from '@Stores/ValidationStore';
import CommonStore from '@Stores/CommonStore';

// Providers
import {
  StaticContentProvider,
  StaticDataProvider,
  VaultProvider,
  WalletsProvider
} from '@Providers';

// @Utils
import { generateGUID } from '@Utils';
import { getParsedCookies } from '@Utils/formatting';
import { generateEventBody, getSignedEvent } from '@Utils/blockchain';

class HAYFTStore extends ValidationStore {
  @observable selectedCard = '';

  @observable HAYFTCards = [];

  @observable offset = 0;

  @observable limit = 25;

  @observable lastPage = false;

  @observable loading = false;

  profiles = observable.array([]);

  @observable checkedAllRows = false;

  @action('HAYFTStore => setHAYFTCards') setHAYFTCards = data => {
    this.HAYFTCards = data;
  };

  @action('HAYFTStore => getHAYFTCards') getHAYFTCards = () => {
    CommonStore.setPending();
    return StaticDataProvider.getHAYFTCards().then(response => {
      const cards = response.data;
      const requests = cards.map(card => this.getCardIconImage(card));
      Promise.all(requests)
        .then(res => this.setHAYFTCards(res))
        .finally(CommonStore.clearPending);
    });
  };

  @action('HAYFTStore =>')
  getCardIconImage = card => {
    const cardArr = card.icon.split('/');
    return StaticContentProvider.getStaticContent(cardArr[0], cardArr[1]).then(
      image => {
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(image);
        return {
          ...card,
          imageUrl
        };
      }
    );
  };

  @action('HAYFTStore => sendCard')
  sendCard = (card, nodeIds) => {
    const tenantId = getParsedCookies().tenant;
    const eventCode = this.checkedAllRows
      ? 'STAY.CONNECTED.Q.ASKED.ALL'
      : 'STAY.CONNECTED.Q.ASKED';

    const payload = {
      guid: generateGUID(),
      initiatorId: `${tenantId}`,
      initiatorName: 'Your HR',
      category: {
        summaryQuestion: card.summaryQuestion,
        icon: card.icon,
        ref: {
          sd: {
            code: card.code,
            res: 'STAY_CONNECTED_QUESTIONS_CATEGORY',
            version: 1
          }
        }
      },
      questions: card.questions.map(question => {
        return {
          order: question.order,
          ref: toJS(question.ref)
        };
      })
    };

    if (!this.checkedAllRows) {
      payload.employeeIds = nodeIds.map(nodeId => `${nodeId}`);
    }

    return WalletsProvider.getNode(tenantId)
      .then(nodeResponse => {
        return WalletsProvider.getPlatformProperties().then(
          platformPropertiesReponse => {
            const event = generateEventBody({
              eventCode,
              payload,
              scAddress: nodeResponse.smart_contract_address,
              tenant: tenantId
            });

            return WalletsProvider.sendEvent(
              getSignedEvent(
                event,
                platformPropertiesReponse.event_ledger_address
              )
            ).catch(err => console.log(err));
          }
        );
      })
      .then(() => this.clearAllTableRowChecked());
  };

  @action('HAYFTStore => getProfiles')
  getProfiles = () => {
    if (!this.loading) {
      this.updateLoading(true);
      return VaultProvider.getProfiles({
        sort: 'audit.updatedAt,desc',
        offset: this.offset,
        limit: this.limit,
        'node.nodeId[gt]': 0
      })
        .then(profilesResponse => {
          this._setProfilesList(profilesResponse);
        })
        .finally(() => this.updateLoading(false));
    }
    return null;
  };

  @action('HAYFTStore => updateLoading')
  updateLoading = loading => {
    if (loading) {
      CommonStore.setPending();
    } else {
      CommonStore.clearPending();
    }
    this.loading = loading;
  };

  @action('HAYFTStore => _setProfilesList')
  _setProfilesList = response => {
    this.lastPage = !response.pagination.hasNext;

    if (this.offset === 0) {
      this.profiles.replace(response.data);
      return;
    }

    const profiles = [...this.profiles, ...response.data];
    this.profiles.replace(profiles);
  };

  @action('HAYFTStore => changeOffset') changeOffset = () => {
    this.offset = this.offset + this.limit;
  };

  @action('HAYFTStore => resetOffset') resetOffset = () => {
    this.offset = 0;
  };

  @action('HAYFTStore => resetProfilesList')
  resetProfilesList = () => {
    this.resetOffset();
    this.profiles.replace([]);
  };

  @action('HAYFTStore => toggleAllTableRowChecked')
  toggleAllTableRowChecked = () => {
    this.profiles.forEach((profile, index) => {
      this.profiles[index].checked = !this.checkedAllRows;
    });
    this.checkedAllRows = !this.checkedAllRows;
  };

  @action('HAYFTStore => toggleTableRowChecked')
  toggleTableRowChecked = rowId => {
    this.profiles.forEach((profile, index) => {
      if (rowId === profile.phone) {
        this.profiles[index].checked = !profile.checked;
      }
    });
    this.checkedAllRows = this.profiles.every(profile => profile.checked);
  };

  @action('HAYFTStore => clearAllTableRowChecked')
  clearAllTableRowChecked = () => {
    this.checkedAllRows = false;
  };
}

export default new HAYFTStore();
