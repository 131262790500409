/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import shortid from 'shortid';
import classnames from 'classnames';

// Material UI
import { Grid, Menu, MenuItem, withStyles } from '@material-ui/core';

// Components
import { CustomTooltip } from '@CommonScene';

// Utils
import { compose } from '@Utils';
import { MoreResting } from '@Utils/constans/icons';

// Styles
import styles from './styles';

class Toolbar extends Component {
  state = {
    anchorEl: null
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    checkedCount: PropTypes.number
  };

  handleCloseMoreMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleClickMoreMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick = clickhandler => {
    this.setState({ anchorEl: null });
    clickhandler();
  };

  render() {
    const { classes, config, checkedCount } = this.props;
    const { anchorEl } = this.state;
    return (
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item xs={11} className={classes.wrapper}>
          {config.items.map(item => (
            <CustomTooltip key={shortid.generate()} label={item.label}>
              <span
                className={classnames(
                  checkedCount > 0 && !item.isDisabled() ? classes.active : null
                )}
                onClick={item.clickHandler}
              >
                {item.icon}
              </span>
            </CustomTooltip>
          ))}
        </Grid>
        {config.moreItems && config.moreItems.length > 0 ? (
          <Grid item xs={1} className={classes.moreButtonWrapper}>
            <MoreResting
              className={classes.moreButton}
              onClick={this.handleClickMoreMenu}
            />
          </Grid>
        ) : null}
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          classes={{ paper: classes.menu }}
          open={Boolean(anchorEl)}
          onClose={this.handleCloseMoreMenu}
        >
          {config.moreItems.map(item => (
            <MenuItem
              id={item.id}
              key={item.id}
              className={classes.menuItem}
              onClick={() => this.handleMenuItemClick(item.clickHandler)}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles),
  observer
)(Toolbar);
