/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

// Utils
import { compose } from '@Utils';
import { useStore } from '@Utils/hooks';
import SignupWrapper from '@AuthScene/scenes/common/SignupWrapper';
import {
  transformMnemonicToPrivateKey,
  normalizePassword,
  convertToSha256,
  encrypt,
  decrypt,
  getBlockchainAddress
} from '@Utils/cryptographic';
import { getCompanySettingPath } from '@Utils/constans/paths';

// Styles
import useStyles from './styles';

const BackupRecoveryPhrase = ({ history }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    SignUpStore: {
      resetStore,
      mnemonicPhrase,
      generateMnemonicPhrase,
      serializedQestions,
      forms: { invitationVerification, enterPassword }
    },
    AuthStore: {
      createContainer,
      getContainer,
      recoveryQuestionsIds,
      joinNetwork
    },
    CommonStore: { pending }
  } = useStore();

  useEffect(() => {
    generateMnemonicPhrase();
  }, []);

  const onRightButtonClickHandler = useCallback(() => {
    transformMnemonicToPrivateKey(mnemonicPhrase).then(privateKey => {
      const passwordPassPhrase = normalizePassword(
        convertToSha256(enterPassword.fields.password.value)
      );
      const questionsPassPhrase = normalizePassword(
        convertToSha256(serializedQestions)
      );

      const defaultContainer = encrypt(privateKey, passwordPassPhrase);
      const restoreContainer = encrypt(privateKey, questionsPassPhrase);
      // TODO: specify correct values for app_version and enc_version fields
      const payload = {
        app_version: 'default_app_version',
        challenges: toJS(recoveryQuestionsIds),
        enc_version: 'default_enc_version',
        payloads: [
          {
            payload: defaultContainer,
            type: 'DEFAULT'
          },
          {
            payload: restoreContainer,
            type: 'RESTORE'
          }
        ]
      };

      createContainer(payload).then(() => {
        const fields = invitationVerification.fields;
        joinNetwork({
          phone: `+${fields.phone.value}`,
          code: fields.invitationCode.value,
          password: enterPassword.fields.password.value,
          care_wallet_id: window.localStorage.getItem('cwId'),
          blockchain_address: getBlockchainAddress(privateKey)
        }).then(() => {
          getContainer()
            .then(containerData => {
              const PK = decrypt(
                containerData.container,
                normalizePassword(
                  convertToSha256(enterPassword.fields.password.value)
                )
              );
              window.localStorage.setItem('PK', PK);
              resetStore();
              history.replace(getCompanySettingPath.companySetting());
            })
            .catch(e => {
              if (e.response.status === 404) {
                history.push('/recovery');
              }
            });
        });
      });
    });
  });

  const onLeftButtonClickHandler = useCallback(() => {
    history.push('/login');
    resetStore();
  });

  return (
    <SignupWrapper
      title={t('auth.signUp.BackupRecoveryPhrase')}
      onLeftButtonClick={onLeftButtonClickHandler}
      onRightButtonClick={onRightButtonClickHandler}
      leftButtonText={'Login'}
      disabledRightButton={pending}
      withStepper={false}
    >
      <div className={classes.description}>
        {t('auth.signUp.BackupRecoveryText')}
      </div>
      <div className={classes.phrase}>{mnemonicPhrase}</div>
    </SignupWrapper>
  );
};

BackupRecoveryPhrase.propTypes = {
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  observer
)(BackupRecoveryPhrase);
