/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Stores
import ReportingStore from '@Stores/ReportingStore';

export const compose = (...funcs) => {
  if (funcs.length === 0) {
    return arg => arg;
  }

  if (funcs.length === 1) {
    return funcs[0];
  }

  return funcs.reduce((a, b) => (...args) => a(b(...args)));
};

export const handleRowClick = baseUrl => (event, userId, redirectTo) => {
  event.stopPropagation();

  const url =
    typeof baseUrl === 'string'
      ? baseUrl + userId
      : baseUrl[0] + userId + baseUrl[1];

  redirectTo(url);
};

export const downloadFile = fileFormat => {
  const { linkToFile } = ReportingStore;
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  a.href = linkToFile;
  a.download = `report.${fileFormat}`;
  a.click();
  document.body.removeChild(a);
};

export function generateGUID() {
  let d = new Date().getTime();
  const now = performance.now();
  let d2 = now * 1000 || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = Math.random() * 16;
    if (d > 0) {
      // eslint-disable-next-line no-bitwise
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // eslint-disable-next-line no-bitwise
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export const decodeJWT = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  return JSON.parse(jsonPayload);
};

export const serializeQuestions = (fields, recoveryQuestions) => {
  const formData = [
    {
      questionId: fields.question_1.value,
      answer: fields.answer_1.value
    },
    {
      questionId: fields.question_2.value,
      answer: fields.answer_2.value
    },
    {
      questionId: fields.question_3.value,
      answer: fields.answer_3.value
    }
  ];

  return recoveryQuestions
    .map(question => {
      const selectedQuestion = formData.find(
        item => item.questionId === question.id
      );
      if (selectedQuestion) {
        return {
          id: selectedQuestion.questionId,
          answer: selectedQuestion.answer.toLowerCase()
        };
      }
      return { id: question.id, answer: 'EMPTY' };
    })
    .sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    })
    .map(item => `"${item.id}":"${item.answer}"`)
    .join('|')
    .replace(/\s+/g, '');
};

export { default as sorting } from './sorting';
