// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
// Material UI
import {
  CircularProgress,
  Grid,
  IconButton,
  Snackbar,
  Typography,
  withStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
// Components
import MainForm from '@MyTeamScene/scenes/NewTeamMember/components/MainForm';
import {
  PrimaryButton,
  SecondaryButton
} from '@CommonScene/materialUIComponents/PrimaryButton';
import {
  BlockWrapper,
  CustomTooltip,
  MainContentWrapper
} from '@CommonScene/index';
import ProfileViewForm from '@MyTeamScene/scenes/TeamMemberProfile/components/ProfileViewForm';
import StatusChip from '@CommonScene/materialUIComponents/statusChip';
// Utils
import { AbsentIcon, IconEdit } from '@Utils/constans/icons';
import { compose } from '@Utils';
// Styles
import styles from './styles';

export class TeamMemberProfile extends Component {
  state = {
    snackBar: {
      show: false
    },
    viewOnly: true,
    phone: ''
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    MyTeamStore: PropTypes.object.isRequired,
    CommonStore: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentWillUnmount() {
    const {
      MyTeamStore: { clearForms }
    } = this.props;
    clearForms();
  }

  componentDidMount() {
    this.loadTeamMemberProfile();
  }

  loadTeamMemberProfile = () => {
    const {
      MyTeamStore: { loadMemberProfile },
      match
    } = this.props;
    const { phone } = match.params;
    loadMemberProfile(phone);
  };

  getHeaderOptions() {
    return {
      title: 'Team Member Profile'
    };
  }

  validateForm = form => {
    const {
      MyTeamStore: {
        updateTeamMember,
        validateUniqueField,
        validateFields,
        validateLineManagerExists
      },
      history
    } = this.props;
    const validateLineManagerReq = form.fields.lineManager.value
      ? validateLineManagerExists(form.fields.lineManager.value)
      : null;
    const phone = `+${form.fields.phone.value}`;
    Promise.all([validateFields])
      .then(() =>
        Promise.all([
          validateUniqueField('email', form.fields.email.value, phone),
          validateLineManagerReq
        ])
      )
      .then(() => {
        if (!Object.values(form.fields).some(field => field.error != null)) {
          updateTeamMember(form.fields).then(() => {
            history.push('/my-team');
          });
        } else {
          const snackBar = { ...this.state.snackBar };
          snackBar.show = true;
          snackBar.message =
            'Validation failed. Please complete the form without validation errors';
          snackBar.severity = 'error';
          this.setState({ snackBar });
        }
      });
  };

  handleCloseSnackbar = (event, reason) => {
    console.log(reason);
    if (reason === 'clickaway') {
      return;
    }

    const snackBar = { ...this.state.snackBar };
    snackBar.show = false;
    this.setState({ snackBar });
  };

  updateViewOnly = viewOnly => {
    this.setState({ viewOnly });
  };

  handleCancel = () => {
    const {
      MyTeamStore: { clearForms }
    } = this.props;
    this.setState({ viewOnly: true });
    clearForms();
    this.loadTeamMemberProfile();
  };

  renderForm(form, formClass, viewOnly) {
    const { classes } = this.props;
    if (viewOnly) {
      return <ProfileViewForm form={form} justify="flex-start" />;
    }
    return (
      <MainForm
        form={form}
        formClass={formClass}
        hideInviteCheckbox
        justify="flex-start"
        fieldLabelClass={classes.formLabelWrapper}
        fieldValueClass={classes.formValueWrapper}
        fieldWrapperClass={classes.fieldWrapperClass}
      />
    );
  }

  render() {
    const {
      MyTeamStore: { forms },
      CommonStore: { pending },
      classes
    } = this.props;
    const { snackBar, viewOnly } = this.state;
    const fields = forms.teamMemberCreateForm.fields;
    const name = `${fields.firstName.value} ${fields.lastName.value}`;
    const jobTitle = !fields.jobTitle.name ? (
      <AbsentIcon />
    ) : (
      fields.jobTitle.name
    );
    return (
      <MainContentWrapper headerOptions={this.getHeaderOptions()}>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={3}>
            <BlockWrapper>
              {pending ? (
                <CircularProgress />
              ) : (
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <img src="/public/img/account.png" />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" className={classes.colorPrimary}>
                      {name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      className={classes.colorPrimary}
                    >
                      {jobTitle}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.statusChip}>
                    <StatusChip text={forms.invitationStatus} />
                  </Grid>
                </Grid>
              )}
            </BlockWrapper>
          </Grid>
          <Grid item xs={9}>
            <BlockWrapper className={classes.blockWrapper}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Typography variant="subtitle1" className={classes.label}>
                  Member Details
                  {viewOnly ? (
                    <CustomTooltip key="edit-icon" label="Edit">
                      <IconButton
                        aria-label="edit"
                        component="span"
                        onClick={() => this.updateViewOnly(false)}
                        style={{ backgroundColor: 'transparent' }}
                      >
                        <IconEdit className={classes.pencil} />
                      </IconButton>
                    </CustomTooltip>
                  ) : null}
                </Typography>
                {pending ? (
                  <CircularProgress />
                ) : (
                  this.renderForm(
                    forms.teamMemberCreateForm,
                    classes.form,
                    viewOnly
                  )
                )}
                {!viewOnly ? (
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <SecondaryButton onClick={this.handleCancel}>
                        cancel
                      </SecondaryButton>
                    </Grid>
                    <Grid item>
                      <PrimaryButton
                        disabled={!forms.teamMemberCreateForm.meta.isValid}
                        onClick={() => {
                          this.validateForm(forms.teamMemberCreateForm);
                        }}
                      >
                        save
                      </PrimaryButton>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
              <Snackbar
                open={snackBar.show}
                autoHideDuration={3000}
                onClose={this.handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Alert
                  onClose={this.handleCloseSnackbar}
                  severity={snackBar.severity}
                >
                  {snackBar.message}
                </Alert>
              </Snackbar>
            </BlockWrapper>
          </Grid>
        </Grid>
      </MainContentWrapper>
    );
  }
}

export default compose(
  withStyles(styles),
  inject('MyTeamStore', 'CommonStore'),
  withRouter,
  observer
)(TeamMemberProfile);
