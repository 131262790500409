/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

// Components
import { BarChart, FromToDateFilter } from '@CommonScene';
import CasesTable from '@DashboardsScene/scenes/COVID19/components/CasesTable';
// import CasesStatistics from '@DashboardsScene/scenes/COVID19/components/CasesStatistics';

// Utils
import { compose } from '@Utils';

// Constants
import { withRouter } from 'react-router-dom';

export class COVID19 extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    AuthStore: PropTypes.object.isRequired,
    COVID19ReportStore: PropTypes.object.isRequired,
    CommonStore: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    const {
      COVID19ReportStore: { getCovidChartData }
    } = this.props;
    getCovidChartData();
  }

  handleChangeDate = (date, key) => {
    const {
      COVID19ReportStore: {
        updateDateRange,
        getCovidOveralResults,
        getCovidChartData,
        getCovidGroupData,
        resetOffset
      }
    } = this.props;

    let currentDate = date;
    if (!currentDate) {
      currentDate = key === 'from' ? 1546300800000 : Date.now();
    }
    updateDateRange({ [key]: currentDate });
    resetOffset();
    getCovidChartData();
    getCovidOveralResults(true);
    getCovidGroupData('REGION');
    getCovidGroupData('OFFICE');
  };

  render() {
    const {
      COVID19ReportStore: { chartData }
    } = this.props;
    return (
      <>
        <FromToDateFilter
          handleChangeDate={this.handleChangeDate}
          dateRange={this.props.COVID19ReportStore.dateRange}
        />
        <p />
        <BarChart
          data={chartData}
          title="COVID-19 Confirmed Employees Daily Statistics"
        />
        {
          // <CasesStatistics />
        }
        <CasesTable />
      </>
    );
  }
}

export default compose(
  inject('AuthStore', 'COVID19ReportStore', 'CommonStore'),
  withRouter,
  observer
)(COVID19);
