/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

export const statusMapping = {
  Uploaded: {
    style: {
      color: '#A095AE',
      backgroundColor: '#E7E7E7'
    }
  },
  Active: {
    style: {
      color: '#6FC960',
      backgroundColor: '#D8F5D3'
    }
  },
  Invited: {
    style: {
      color: '#FFA91E',
      backgroundColor: '#FEEDD1'
    }
  },
  'Invite expired': {
    style: {
      color: '#FE4848',
      backgroundColor: '#FFD9D9'
    }
  },
  BLOCKED: {
    style: {
      color: '#4657ED',
      backgroundColor: '#CDD5FF'
    }
  },
  ACTIVE: {
    style: {
      fontSize: 14,
      color: '#6FC960',
      backgroundColor: '#D8F5D3'
    }
  },
  OUTDATED: {
    style: {
      fontSize: 14,
      color: '#A095AE',
      backgroundColor: '#E7E7E7'
    }
  },
  PREASSIGNED: {
    style: {
      fontSize: 14,
      color: '#9B51E0',
      backgroundColor: '#E9E2F4'
    }
  },
  ARCHIVED: {
    style: {
      fontSize: 14,
      color: '#828282',
      backgroundColor: '#F1F2F4'
    }
  },
  INVITATION_PENDING: {
    style: {
      fontSize: 14,
      color: '#FFA91E',
      backgroundColor: '#FEEDD1'
    }
  },
  INVITATION_EXPIRED: {
    style: {
      fontSize: 14,
      color: '#FE4848',
      backgroundColor: '#FFD9D9'
    }
  },
  DECLINED: {
    style: {
      fontSize: 14,
      color: '#3D8EED',
      backgroundColor: '#DCECFF'
    }
  },
  PENDING_APPROVAL: {
    style: {
      fontSize: 14,
      color: '#F35598',
      backgroundColor: '#FBD8EF'
    }
  },
  CREATED: {
    style: {
      fontSize: 14,
      color: '#3D8EED',
      backgroundColor: '#DCECFF'
    }
  },
  ASSIGNED_IDENTITY: {
    style: {
      fontSize: 14,
      color: '#FFA91E',
      backgroundColor: '#FEEDD1'
    }
  },
  ASSIGNED_WALLET: {
    style: {
      fontSize: 14,
      color: '#6FC960',
      backgroundColor: '#D8F5D3'
    }
  },
  NEW_USER: {
    style: {
      fontSize: 14,
      color: '#3D8EED',
      backgroundColor: '#DCECFF'
    }
  },
  NEW: {
    style: {
      fontSize: 14,
      color: '#3D8EED',
      backgroundColor: '#DCECFF'
    }
  },
  FINISHED: {
    style: {
      fontSize: 14,
      color: '#6FC960',
      backgroundColor: '#D8F5D3'
    }
  },
  STARTED: {
    style: {
      fontSize: 14,
      color: '#FFA91E',
      backgroundColor: '#FEEDD1'
    }
  },
  FAILED: {
    style: {
      fontSize: 14,
      color: '#FE4848',
      backgroundColor: '#FFD9D9'
    }
  }
};
