import Blockchain from '@Utils/blockchain/Blockchain';
import { generateGUID } from '@Utils';

export const getSignedEvent = (eventBody, eventLedgerAddress) => {
  const hash = Blockchain.generateEventHash(eventBody, eventLedgerAddress);
  const signature = Blockchain.signHash(
    hash,
    window.localStorage.getItem('PK')
  );
  return { ...eventBody, signature };
};

export const generateEventBody = data => {
  return {
    code: data.eventCode,
    correlation_id: generateGUID(),
    from_role: 'x1',
    operation_timestamp: new Date().getTime(),
    payload: data.payload,
    reference_id: generateGUID(),
    to: '0x0000000000000000000000000000000000000000',
    to_role: 'x2',
    type: data.eventCode.replace(/\./g, '_'),
    version: 1,
    from: data.scAddress,
    reference_check_sum:
      '115035fdd3d1672888e192545ba71b8ef628c3119943a61de05da8ab033b200a',
    tenant: data.tenant
  };
};
