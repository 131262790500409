// Core
import { action, observable } from 'mobx';
// Stores
import ValidationStore from '@Stores/ValidationStore';
import CommonStore from '@Stores/CommonStore';
// Providers
import { StaticContentProvider, VaultProvider } from '@Providers';
import { debounce } from 'lodash';
import { DELAY_REQUEST } from '@Utils/constans';

// @Utils

class ReportedTripsStore extends ValidationStore {
  @observable activeTab = 'past';

  @observable offset = 0;

  @observable limit = 25;

  @observable lastPage = false;

  @observable loading = false;

  @observable query = '';

  @observable loadedDataCount = 0;

  trips = observable.array([]);

  countryFlags = observable.array([]);

  @action('ReportedTripsStore => getTrips')
  getTrips = () => {
    if (!this.loading) {
      this.updateLoading(true);
      let queryParams = {
        sort: 'trip.statusAt,desc',
        offset: this.offset,
        limit: this.limit
      };
      if (this.query) {
        queryParams = { ...queryParams, 'profile.fullName': this.query };
      }
      // workaround due to api limitation:
      // loopFunction will be used to continually call api
      // until filtered data is equal to limit or all data has been loaded
      const loopFunction = () => {
        return new Promise((resolve, error) => {
          queryParams.offset = this.offset;
          queryParams.limit = this.limit - this.loadedDataCount;
          VaultProvider.getTravelReport(queryParams)
            .then(this._setUpcomingTripList)
            .then(this.changeOffset)
            .then(() => {
              if (!this.lastPage && this.loadedDataCount < this.limit) {
                return loopFunction();
              }
              return null;
            })
            .then(resolve)
            .catch(error);
        });
      };
      return loopFunction()
        .then(() => {
          this.updateLoadedDataCount(0);
          return null;
        })
        .then(() => this.loadCountryFlags())
        .finally(() => this.updateLoading(false));
    }
    return null;
  };

  @action('ReportedTripsStore => loadCountryFlags')
  loadCountryFlags = () => {
    const icons = [...this.trips].map(data => data.trip.details.city.icon);
    const iconSet = [...new Set(icons)];
    const iconSetRequest = iconSet.map(icon => {
      const iconArr = icon.split('/');
      return StaticContentProvider.getStaticContent(iconArr[0], iconArr[1])
        .then(image => {
          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(image);
          return {
            id: icon,
            imageUrl
          };
        })
        .catch(error => {
          console.log('Error finding icon for ', icon);
          console.log(error);
          return {
            id: icon,
            imageUrl: null
          };
        });
    });
    return Promise.all(iconSetRequest).then(response => {
      this.updateCountryFlags(response);
    });
  };

  @action('ReportedTripsStore => updateCountryFlags')
  updateCountryFlags = countryFlags => {
    this.countryFlags = countryFlags;
  };

  @action('ReportedTripsStore => getCountryFlagsById')
  getCountryFlagsById = id => {
    const countryFlag = this.countryFlags.find(flag => flag.id === id);
    return countryFlag ? countryFlag.imageUrl : '';
  };

  @action('ReportedTripsStore => updateLoading')
  updateLoading = loading => {
    if (loading) {
      CommonStore.setPending();
    } else {
      CommonStore.clearPending();
    }
    this.loading = loading;
  };

  @action('ReportedTripsStore => _setTripList')
  _setTripList = response => {
    this.lastPage = !response.pagination.hasNext;

    if (this.offset === 0) {
      this.trips.replace(response.data);
      return;
    }

    const trips = [...this.trips, ...response.data];
    this.trips.replace(trips);
  };

  @action('ReportedTripsStore => _setUpcomingTripList')
  _setUpcomingTripList = response => {
    this.lastPage = !response.pagination.hasNext;

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date();
    tomorrow.setHours(0, 0, 0, 0);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const upcomingTrips = [...response.data].filter(data => {
      const fromDate = new Date(data.trip.details.from);
      const toDate = !data.trip.details.to
        ? null
        : new Date(data.trip.details.to);
      const isPastTrip = fromDate < today && (!toDate || toDate < tomorrow);
      if (this.activeTab === 'upcoming') {
        return !isPastTrip;
      }
      return isPastTrip;
    });
    this.updateLoadedDataCount(this.loadedDataCount + upcomingTrips.length);
    if (this.offset === 0) {
      this.trips.replace(upcomingTrips);
      return;
    }
    const trips = [...this.trips, ...upcomingTrips];
    this.trips.replace(trips);
  };

  @action('ReportedTripsStore => changeOffset') changeOffset = () => {
    this.offset = this.offset + this.limit;
  };

  @action('ReportedTripsStore => resetOffset') resetOffset = () => {
    this.offset = 0;
  };

  @action('ReportedTripsStore => updateLoadedDataCount')
  updateLoadedDataCount = count => {
    this.loadedDataCount = count;
  };

  @action('ReportedTripsStore => updateLastPage') updateLastPage = lastPage => {
    this.lastPage = lastPage;
  };

  @action('ReportedTripsStore => updateActiveTab') updateActiveTab = tab => {
    this.activeTab = tab;
  };

  @action('ReportedTripsStore => resetTripList')
  resetTripList = () => {
    this.resetOffset();
    this.trips.replace([]);
  };

  @action('ReportedTripsStore => setQuery') setQuery = query => {
    this.resetOffset();
    this.query = query;
    this.search();
  };

  search = debounce(this.getTrips, DELAY_REQUEST);
}

export default new ReportedTripsStore();
