/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

// Components
import { DataTable } from '@CommonScene';

// Icons
import { Person } from '@Utils/constans/icons';

// Configs
import { tableConfig } from '@Assets/config/tables/covid19';

// Utils
import { compose } from '@Utils';

export class CasesTable extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  };

  componentDidMount() {
    const {
      store: { getCovidOveralResults, setPageParams, dateRange, offset, limit },
      type
    } = this.props;
    setPageParams({
      offset,
      limit,
      'testResult.from': dateRange.from,
      'testResult.to': dateRange.to,
      'testResult.type': type
    });
    getCovidOveralResults();
  }

  componentWillUnmount() {}

  fetchTableData = () => {
    const {
      store: { changeOffset, getCovidOveralResults }
    } = this.props;

    changeOffset();
    getCovidOveralResults();
  };

  setDefaultQuery = () => {
    const {
      store: { clearQuery, getCovidOveralResults }
    } = this.props;

    clearQuery();
    getCovidOveralResults(true);
  };

  render() {
    const {
      store: { tableData, lastPage, setQuery, query },
      title
    } = this.props;

    return (
      <DataTable
        tableConfig={tableConfig}
        tableData={tableData}
        fetchTableData={this.fetchTableData}
        hasMoreData={!lastPage}
        title={title}
        TitleIcon={Person}
        searchConfig={{
          setQuery,
          queryValue: query,
          setDefaultQuery: this.setDefaultQuery
        }}
      />
    );
  }
}

export default compose(observer)(CasesTable);
