/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

const styles = () => ({
  container: {
    position: 'relative'
  },
  root: {
    flexDirection: 'row'
  },
  colorPrimary: {
    color: '#2F0A6B',
    border: '1px solid rgba(47, 10, 107, .2)',
    backgroundColor: '#fff',
    boxShadow: 'none'
  },
  toolbarWrapper: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    minHeight: '54px',
    padding: '0 20px'
  },
  loaderRoot: {
    position: 'absolute',
    top: '54px',
    width: '100%'
  },
  loaderColorPrimary: {
    backgroundColor: '#E9E2F4'
  },
  loaderBarColorPrimary: {
    backgroundColor: '#DCB8FD',
    borderRadius: '3px'
  },
  dialogWrapper: {
    borderRadius: '16px',
    border: '1px solid #E9E2F4',
    boxSizing: 'border-box',
    width: '655px',
    height: '525px',
    maxWidth: '1000px'
  },
  dialogContent: {
    padding: '32px',
    '&:first-child': {
      paddingTop: '32px'
    }
  },
  dialogColorPrimary: {
    color: '#2F0A6B'
  },
  icon: {
    color: '#CDCADC'
  },
  closeIcon: {
    cursor: 'pointer',
    float: 'right'
  },
  noScroll: {
    overflowY: 'auto'
  },
  button: {
    float: 'right'
  }
});

export default styles;
